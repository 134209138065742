body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body,
html {
    height: 100%;
    margin: 0;
    padding: 0;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#root {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.expanding-centered {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100%;
    justify-content: center;
}

.margin-center {
    margin: 0 auto;
}

a {
    color: inherit;
}

.stat-card-grey {
    border-radius: 6px;
    padding: 5px;
    font-size: 10px;
}

.font-small {
    font-size: small;
}
