.container {
    max-width: 100%;
    margin: 0px;
    padding-right: 0;
    padding-left: 0;
}

.left-sidebar {
    position: fixed;
    width: 260px;
    height: 100%;
    transition: 0.2s ease-in;
    border-right: 2px solid #e0e0e0;
    z-index: 1;
    background-color: white;
}

.page-wrapper {
    display: block;
    margin-left: 260px;
    height: 100%;
    min-height: 100%;
}

.sidebar-nav {
    ul {
        .sidebar-item {
            width: 100%;
            margin-bottom: 10px;
            list-style: none;
            font-size: 1rem;
        }
    }
}

.sidebar-items {
    padding-left: 0;
}

.sidebar-dropdown {
    bottom: 0px;
    position: fixed;
    width: 260px;
}

.sidebar-dropdown-link {
    cursor: pointer;
    padding: 12px 15px;
    display: flex;
    border-radius: 4px;
    white-space: nowrap;
    align-items: center;
    position: relative;
    color: #000000;
    font-weight: bold;
}

.sidebar-chevron {
    display: none;
}

.sidebar-logo {
    max-width: 220px;
}

@media only screen and (max-width: 768px) {
    .sidebar-chevron {
        position: absolute;
        right: -35px;
        background-color: white;
        width: 35px;
        height: 35px;
        top: 5px;
        border-top: 2px solid #e0e0e0;
        border-right: 2px solid #e0e0e0;
        border-bottom: 2px solid #e0e0e0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.1em;
        border-bottom-right-radius: 50%;
        border-top-right-radius: 50%;

        & .chevron {
            transition: transform 0.2s;
        }
    }

    .page-wrapper {
        margin-left: 0px;
    }

    .left-sidebar {
        left: -260px;
        background-color: white;
    }

    .sidebar-rotate {
        .chevron {
            transform: rotate(180deg);
        }
    }

    .open-sidebar {
        left: 0px;
    }
}

.powered-by {
    display: flex;
    padding-left: 12px;
    font-size: 90%;
}
