@import "./Styles/scss/custom-bootstrap.scss";

body,
html {
    height: 100%;
    margin: 0;
    padding: 0;
}

body {
    background-color: #f1f1f1;
    font-family: "Poppins", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#root {
    height: 100%;
    display: flex;
    flex-direction: column;
    min-height: 100%;
}

.container {
    margin: 0 auto;
    max-width: 100%;
    width: 100%;
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

.App {
    min-height: 100vh;
}

.font-0-75rem {
    font-size: 0.75rem;
}

.font-1rem {
    font-size: 1rem;
}

.font-1-5rem {
    font-size: 1.5rem;
}

.font-2rem {
    font-size: 2rem;
}

.font-3rem {
    font-size: 3rem;
}

@media (min-width: 768px) {
    .card-light > .card-body > .row > .border-md-left:not(:last-child) {
        border-right: 1px solid #333 !important;
    }

    .card-dark > .card-body > .row > .border-md-left:not(:last-child) {
        border-right: 1px solid white !important;
    }
}

.text-bold {
    font-weight: bold;
}

.text-prewrap {
    white-space: pre-wrap;
}

.badge {
    font-size: 100%;
    font-weight: 400;
    width: 100%;
    padding: 0.5rem;
    border-radius: 16px;
}

@media (min-width: 320px) {
    .login-bg {
        display: none;
    }

    .login-form-container {
        height: auto;
        background: url("https://media.g3r.co.uk/e-recovery/e-recovery-login-photo.png");
        background-repeat: no-repeat;
        background-size: cover;
    }

    .login-form {
        background: white;
        padding: 15px;
        margin: 15px;
        border-radius: 15px;
    }
}

@media (min-width: 1025px) {
    .login-bg {
        display: block;
        position: absolute;
        width: 50%;
    }

    .login-form-container {
        margin-left: 50%;
        background: inherit;
    }
}

.login-bg {
    height: 100%;
    background: url("https://media.g3r.co.uk/e-recovery/e-recovery-login-photo.png");
    background-repeat: no-repeat;
    background-size: cover;
}

.login-form-container {
    height: 100%;
}

.login-logo {
    background: url("https://media.g3r.co.uk/e-recovery/e-recovery-logo.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: 54px;
}

.text-muted {
    color: #9b9b9b !important;
    font-style: normal;
    font-size: 1rem;
}

.text-red {
    color: #cf161c;
}

.font-15rem {
    font-size: 1.5rem !important;
}

a {
    text-decoration: none;
}

.text-grey {
    color: #9b9b9b;
}

.right-sidebar {
    background-color: white;
    height: 100%;
    position: fixed;
    right: 0;
}

.col-auto {
    margin-bottom: 0.5rem;
}

.flex-column > .col {
    margin-bottom: 0.5rem;
}

.min-100 {
    min-height: 100%;
}
.react-datepicker-wrapper {
    display: inline-block;
    padding: 0;
    border: 0;
}

.text-upper {
    text-transform: uppercase;
}

.btn-circle {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
    border-radius: 50% !important;
}

.bg-yellow {
    background-color: yellow;
}

.accordion-button {
    background-color: transparent !important;
    font-size: 2rem;
    font-weight: 900;
    color: black !important;
}

.accordion-item {
    border-top: none !important;
    border-right: none !important;
    border-left: none !important;
}

// react-toast-notifications z-index override
.react-toast-notifications__container {
    z-index: 3000 !important;
}

.bg-orange {
    background-color: $orange !important;
}

.bg-purple {
    background-color: $purple;
}

.nav-link {
    cursor: pointer;
}

.input-group-text {
    display: block; /* or in-line block according to your requirement */
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left;
}

.text-white {
    color: white;
}

.dropzone {
    height: 200px;
    border-style: dashed;
    border-color: #ededee;
    border-radius: 6px;
}

.dropzone-active {
    border-color: #c42034;
}

// fix for AddressLookup in modals
.pac-container {
    z-index: 100000;
}

.d-grid {
    height: inherit;
    min-height: inherit;

    a {
        display: inherit;
    }
}

.border-grid {
    border: 1px black solid;
    border-radius: 360px;
}

.bg-grey-200 {
    background-color: $gray-200;
}

.bg-grey-300 {
    background-color: $gray-300;
}

.bg-grey-400 {
    background-color: $gray-400;
}

.bg-grey-500 {
    background-color: $gray-500;
}

.bg-grey-600 {
    background-color: $gray-600;
}

.thumb.main:before {
    content: "SUCCESS";
    color: red;
    position: absolute;
    top: -20px;
    left: 0px;
}

.thumb-inner {
    display: flex;
    overflow: hidden;
    max-height: "-webkit-fill-available";
}

.thumb-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 16px;
}

.thumb-img {
    display: block;
    width: 100%;
    height: auto;
}

.thumb-delete {
    border-radius: 0px;
    width: 100%;
}

.upload-thumb {
    background-color: #f4f6f9;
    border: 2px solid;
    border-color: #ededee;
    color: #fff;
    width: 200px;
    height: 150px;
    margin-right: 5px;
    margin-bottom: 5px;
}

.upload-thumb .main {
    border-color: #c42034;
}
