$primary: #0074d9;
$secondary: #333;
$danger: #ff4136;
$bs-dark: black;
$orange: #fd7e14;
$info-tag: #e9ecef;

$custom-theme-colors: (
    info-tag: $info-tag,
);
