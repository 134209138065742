.sidebar-link {
    align-items: center;
    border-radius: 4px;
    display: flex;
    padding: 12px 15px;
    position: relative;
    white-space: nowrap;

    & .sidebar-link-content {
        color: #000000;
        opacity: 0.3;
        font-weight: bold;

        &:hover {
            text-decoration: none;
            color: black;
            opacity: 0.6;
            transition: all 0.5s ease;
        }
    }

    &.active {
        * {
            opacity: 1;
        }
    }
}

.sidebar-item .first-level {
    padding: 7px 12px 7px 30px;
}
